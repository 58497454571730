// Raw colors

$black: #333333;
$white: white;
$blue: #1A82C0;
$red: #D22231;
$maroon: #D22231;
$grey: #F4F4F4;

// Applied colors
