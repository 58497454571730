.videos_section.videos {
    text-align: center;
    padding: 10px;
    .btn {
        margin-top:20px;
        display: inline-block;
    }
    padding: 15px;
    .video-container {
        margin-bottom: 20px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }    

}


.videos {
    padding: 40px 0;
    .video-container {
        margin-bottom: 20px;
    }
}