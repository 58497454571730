section.body-text {

}

.container {
    margin:auto;
    max-width:1250px;
    width:100%;
    padding:0 15px;
    .row {
        margin-left: -15px;
        margin-right: -15px;
    }
}
.wrapper {
    padding: 20px 0;
    text-align:center;
    h3 {
        text-transform: uppercase;
        font-weight:700;
        font-size:20px;
        margin-bottom:20px;
    }
    &--red {
        background: $red;
    }
    &--blue {
        background: $blue;
    }
}
