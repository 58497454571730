.searchform {
    position:relative;
    input {
        padding: 10px 5px;
        border: 0;
        width: 280px !important;
        height: 50px !important;
        display: block;
        position: absolute;
        background-color: white !important;
        color: black !important;
        opacity: 1 !important;
        top: 27px;
        right: 0;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
        z-index: 10;
        &::placeholder {
            color: black !important;
        }
    }
    .searchicon {
        cursor: pointer;
        &:before {
            content: '\f002';
            font-family: 'Font Awesome 5 Pro';
            display: inline-block;
            font-size: 14px;
            padding-left: 10px;
            vertical-align: -5px;
        }
    }
}