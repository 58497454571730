.news-container {
    padding: 40px 0;
    .post {
        padding:20px;
        margin-bottom: 30px;
        border-radius: 3px;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
        line-height: 1.4;
        background-color: #f7f7f7;
        h3 {
            color: $red;
            font-size:20px;
            line-height: 30px;
        }
        time {
            display:inline-block;
            opacity:0.7;
            padding:10px 0;
            font-size:14px;
        }
        .details {
            color: $red;
            font-weight:700;
            padding-top:10px;
            display:inline-block;
        }
    }
}

.article-body {
    padding:40px 0;
    line-height: 1.6em;
    ul {
      margin: 0 0 10px 20px;
      list-style:disc;
    }
    p {
      padding-bottom: 10px;
    }
    a {
      color: $red;
    }
  }

  .sidebar {
    padding: 0;
    background: $blue;
    color: white;
    h3 {
      color: white;
      text-transform: uppercase;
      text-align:center;
      font-size:18px;
      line-height: 30px;
      margin-bottom: 20px;
      font-weight: 700;
    }
    #custom_html-2 {
        background: $red;
    }
    .widget {
        padding: 20px;
    }
    
  }
  .nav-links {
    display: flex;
    padding: 25px 0;
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: rgba(51,51,51,0.12);
    justify-content: space-between;
    .nav-previous{
        width: 45%;
        padding-left: 35px;
        margin-right: 30px;
        display:flex;
        position: relative;
        flex-flow: column wrap;
        i {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 42px;
            color: rgba(51,51,51,0.12);
        }
        
    }
    .nav-next {
        width: 45%;
        padding-right: 35px;
        margin-left: 30px;
        display:flex;
        position: relative;
        flex-flow: column wrap;
        text-align: right;
        i {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 42px;
            color: rgba(51,51,51,0.12);
        }

    }
    .post-title {
        font-size:20px;
        line-height: 30px;
    }
    .meta-nav {
        text-transform:uppercase;
    }
}