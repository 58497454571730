.medication-check {
  padding: 20px 0;
  padding-right: 20px;

  input {
    padding: 12px 17px;
    width: 100%;
    background: #f6f6f6;
    border: 0;
    font-size: 1.2em;
    font-weight: 600;
    color: $black;
    color: black !important;

    &::placeholder {
      color: black !important;
    }
  }

  .total {
    padding: 10px 0;
    text-align: right;
    font-weight: 700;
    opacity: 0.5;
  }

  .Collapsible {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin-bottom: 10px;

    &__trigger {
      font-weight: 600;
      padding: 10px;
      box-sizing: border-box;
      width: 100%;
      cursor: pointer;
      position: relative;
      display: block;

      &:before {
        content: '';
        width: 25px;
        height: 25px;
        display: inline-block;
        margin-right: 10px;
        background-size: contain;
        background-repeat: no-repeat;
        vertical-align: -8px;
      }

      &:after {
        content: '\f077';
        font-family: 'Font Awesome 5 Pro';
        display: inline-block;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        transform-origin: center center;
        transition: all 350ms ease-in-out;
      }

      &.is-open {
        &:after {
          transform: translateY(-50%) rotate(0deg);
        }
      }

      &__trigger {
        font-weight: 600;
      }
    }

    &__contentInner {
      padding: 10px;

      p {
        padding-bottom: 0.5em;
        line-height: 1.2;
      }
    }
  }

  .btn {
    margin: auto;
    margin-top: 20px;
    display: inline-block;
    border: 0 !important;
  }

  .loading-btn {
    text-align: center;
  }

  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .no-results {
    text-align: center;
    font-size: 1.4;
  }
}

.prohibited-in-and-out-of-competition {
  .Collapsible__trigger {
    &:before {
      background-image: url(../assets/images/prohibited.png);
    }
  }
}

.prohibited-in-competition-only {
  .Collapsible__trigger {
    &:before {
      background-image: url(../assets/images/comp.png);
    }
  }
}

.prohibited-in-and-out-of-competition-in-males-only {
  .Collapsible__trigger {
    &:before {
      background-image: url(../assets/images/male.png);
    }
  }
}

.prohibited-in-competition-only-in-certain-sports {
  .Collapsible__trigger {
    &:before {
      background-image: url(../assets/images/certain.png);
    }
  }
}

.permitted {
  .Collapsible__trigger {
    &:before {
      background-image: url(../assets/images/allowed.png);
    }
  }
}

.filter-list {
  display: flex;
  margin-top: 10px;

  div {
    flex: 1;
    display: block;
    position: relative;
    background: #042945;
    padding: 40px 10px;
    margin-right: 10px;
    cursor: pointer;
    text-align: center;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 350ms ease-in-out;
    border-radius: 3px;

    &.not-selected {
      opacity: 0.3;
      filter: grayscale(100%);
    }

    h3 {
      text-transform: uppercase;
      font-weight: bold;
    }

    color: white;

    &:last-of-type {
      margin-right: 0;
    }

    &.permitted {
      background-color: #0b8e36;
      background-image: url(../assets/images/allowed.png);
    }

    &.prohibited-at-all-times {
      background-color: #d3212b;
      background-image: url(../assets/images/prohibited.png);
    }

    &.prohibited-in-competition {
      background-color: #f39200;
      background-image: url(../assets/images/comp.png);
    }

    &.prohibited-in-certain-sports {
      background-color: #662483;
      background-image: url(../assets/images/certain.png);
    }

    &.prohibited-male-athletes {
      background-color: #0099da;
      background-image: url(../assets/images/male.png);
    }
  }
}