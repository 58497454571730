.top-header {
    background:$blue;
    padding:0.5em;
    color:white;
    font-size:0.8em;
    @include for-phone-only {
        display:none;
    }
    .button {
        display:inline-block;
        margin-right:20px;
    }
}

.main-header {
    padding:1em 0;
    
    @include for-phone-only {    
        text-align: center;
     }
    .logo {
        max-width:220px;
        position: relative;
        @include for-phone-only {
            max-width: 100%;
            width:100%;
            text-align:center;
            
        }
        img {
            max-width:220px;
            margin-right: 80px;
            @include for-phone-only { 
                display:inline-block;
            }
        }
        

    }
    
}

.page-title {
    background-position: center center;
    background-size:cover;
    padding: 70px 0;
    position:relative;
    text-align:center;
    margin-bottom: 20px;
    &:before {
        content:'';
        position: absolute;
        left:0;
        right:0;
        bottom:0;
        top:0;
        background: black;
        opacity: 0.5;
    }
    h1 {
        color: white;
        text-transform: uppercase;
        font-weight: 900;
        position: relative;
        font-size:30px;
        margin: 0;
    }
    h2 {
        margin: 0;
        padding: 5px 0;
        color: #eded0e;
        font-size: 18px;
        line-height: 26px;
        text-transform: none;
        position: relative;
    }
    &.post-title {
        &:before {
            opacity:0.1;
        }
        h1 {
            color: $blue;
        }
    }
}

.contact-us .page-title {
    margin-bottom: 0;
}