.link_grid {
  display: grid;
  margin-bottom: 20px;
}

.grid {
  &__two {
    grid-template-columns: repeat(2, 1fr);
  }

  &__three {
    grid-template-columns: repeat(3, 1fr);
  }

  &__item {
    aspect-ratio: 2/0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;
    text-align: center;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    i {
      font-size: 4rem;
    }

    svg {
      width: 50px;
    }

    h3 {
      text-transform: uppercase;
      font-size: 18px;
      color: inherit;
    }

    &__content {
      padding: 20px;

      &__icon {
        font-size: 2.5rem;
        padding-bottom: 20px;
      }
    }

  }
}