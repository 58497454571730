.pledge {
    text-align:center;
    margin:15px 0;
    h5 {
        color: #bf0024;
        font-size: 16px;
        font-weight: 700;
        display: inline-block;
        padding-top:10px;
    }
    img {
        border-radius: 100%;
        display:block;
        margin-bottom: 10px;
        margin:auto;
    }
}