#cases_content .case_detail {
    height: auto;
    background: #ffffff;
  }
  
  #cases_filter {
      margin-top:60px;
    h3 {
        text-transform: uppercase;
        color:white;
        font-size:24px;
        font-weight: bold;
        line-height: 36px;
    }
    select, input {
      display: inline-block;
      appearance:none;
      width: 25%;
      color: white;
      border: 0;
      background: rgba(255, 255, 255, 0.2);
      font-size: 14px;
      line-height: 17px;
      height: 38px;
      padding:10px;
    }
  }
  
  select option {
    color: black;
  }
  
  #cases_filter input {
    &.submit_button {
      display: inline-block;
      width: 15%;
    }
  
    &#athelete_name {
      &::placeholder {
        color: white !important;
        opacity:1;
      }
    }
  }
  
  .clear_button {
    padding-left: 10px;
    text-decoration: none;
    color: white;
    text-decoration:none;
  
    &:hover {
      text-decoration: underline;
      color: white;
    }
  }
  
  #cases_content {
    width: 100%;
  
    table {
      width: 100%;
    }
  
    margin-bottom: 20px;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    border-collapse: collapse;
    border-top: 0 !important;
    border-radius: 0 0 3px 3px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  
    th {
      position: relative;
      box-sizing: border-box;
      height: 48px;
      padding: 0 10px 8px;
      text-align: left;
      vertical-align: bottom;
      letter-spacing: 0;
      text-overflow: ellipsis;
      color: white;
      border-top: 0 !important;
      border-left: 1px solid rgba(191, 29, 37, 0.6);
      background: #bf0024 !important;
      font-weight: 700;
      line-height: 24px;
      background-clip: padding-box;
    }
  
    td {
      position: relative;
      box-sizing: border-box;
      height: 48px;
      padding: 10px 10px;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      background: white;
      background-clip: padding-box;
    }
  
    th {
      &:first-of-type {
        width: 130px;
      }
  
      &:nth-of-type(2) {
        width: 120px;
      }
    }
  
    td:first-of-type, th:first-of-type {
      padding-left: 10px;
    }
  
    td:last-of-type {
      text-align: right;
      border-right: 1px solid rgba(0, 0, 0, 0.12);
    }
  
    th:last-of-type {
      text-align: right;
      border-right: 1px solid rgba(0, 0, 0, 0.12);
      padding-right: 10px;
      border-right: 1px solid rgba(191, 29, 37, 0.6);
      text-align: left;
      width: 135px;
    }
  
    .case_detail table td {
      width: 25%;
      padding: 0;
      text-align: left;
      border: 0;
    }
  }
  
  .case_detail_label {
    color: #bf0024;
    font-weight: bold;
  }
  
  .drop_button {
    i {
      margin-left: 10px;
      transition: all 0.15s ease;
    }
  
    &.open i {
      transform: rotate(180deg);
    }
  }
  
  .casess-pagination-simple {
    position: absolute;
    right: 10px;
    bottom: 20px;
  
    a {
      display: inline-block;
      margin: 5px;
      padding: 8px 14px 7px 14px;
      text-decoration: none;
      text-transform: uppercase;
      color: white;
      -webkit-border-radius: 30px;
      border-radius: 30px;
      background: #1a82c0;
      font: normal 12px / 14px 'Source Sans Pro', Helvetica, Arial, Verdana, sans-serif;
    }
  }
  
  #cases_filter {
    width: 100%;
    padding: 10px;
    border-radius: 3px 3px 0 0;
    background: #1a82c0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  input[type="submit"] {
    text-transform: uppercase;
    border-radius: 50px;
    padding: 12px 18px 12px 18px;
    min-height: 38px;
    line-height: 38px;
    padding-top: 0;
    padding-bottom: 0;
    background: $red !important;
    transition: all 350ms ease-in-out;
    &:hover {
      box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15);
      color:white;
    }
    
}

.drop_button {
  
  i {
    transition: all 350ms ease-in-out;
  }
  &.is-active {
    
    i {
      transform:rotate(180deg);
    }
  }
} 

.pagination {
  text-align:right;
  margin-bottom: 20px;
  width: 100%;
  .btn {
    display: inline-block;
  }
}

#cases_content {
  tr {
    td, th {
      &:nth-child(4) {
        max-width: 400px;
      }
    }
  }

}
