.normal-content {
    padding:15px;
    ul {
        list-style: disc outside none;
        margin: 0 0 10px 20px;
    }
    a {
        color: $red;
        text-decoration: underline;
        &.btn {
            color: white;
            text-decoration: none;
        }
    }
     p {

        margin-bottom: 10px;
    
    }
}