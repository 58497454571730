.prefooter {
    padding: 40px 0;

    h3 {
        font-size: 24px;
        line-height: 36px;
        padding-bottom: 10px;
    }
    .slick-dots li button {
        &:before {
            font-size: 12px;
        }
    }
}