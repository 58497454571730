.social__icon {
    width: 2em;
    height:2em;
    text-align:center;
    line-height:2em;
    border-radius:100%;
    background:white;
    transition: all 350ms ease-in-out;
    margin-left:0.5em;
    margin-right:0;
    svg {
        max-width:100%;
        width:auto;
        height: 1em;
        color: $blue;
    }
    &:hover {
        opacity:0.8;
    }
}