* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

:first-child {
  margin-top: 0px;
}

:last-child {
  margin-bottom: 0px;
}

html,
body {
  margin: 0px;
  padding: 0px;
  font-family: 'Source Sans Pro', sans-serif;
  overflow-x: hidden;
  color: $black;
}

html {
  font-size: 14px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: $black;
  transition: all 350ms ease-in-out;

  &:link,
  &:visited,
  &:hover,
  &:active {
  }

  &:hover,
  &:active {
    color: $red;
  }

  &[href^='javascript'] {
  }
}

table {
  width: 100%;

  td,
  th {
    padding: 3px;
    vertical-align: middle;
  }
}

h1,
h2,
h3 {
}

h1,
h2,
h3 {
  a {
    &:link,
    &:visited,
    &:hover,
    &:active {
    }

    &:hover,
    &:active {
    }
  }
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: auto;

  &.alignleft {
    float: left;
    margin-right: 20px;
    margin-bottom: 10px;
  }
}

figure {
  margin: 0px;

  > a {
    display: block;
  }
}

#site-name img {
  display: none;
}

.caps {
  letter-spacing: 1px;
}

.frac {
  font-feature-settings: 'frac';
  font-variant-numeric: diagonal-fractions;
}

.align-center {
  margin: auto;
}

.tablepress {
  margin-top: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  font-size: 1em;
  line-height: 1.4;
  tfoot {
    th {
      position: relative;
      box-sizing: border-box;
      height: 48px;
      padding: 0 18px 8px;
      vertical-align: bottom;
      letter-spacing: 0;
      text-overflow: ellipsis;
      color: rgba(0, 0, 0, 0.54);
      border-top: 0 !important;
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      background: white !important;
      font-weight: 700;
      line-height: 24px;
      padding-top: 10px;
    }
  }
  thead {
    th {
      position: relative;
      box-sizing: border-box;
      height: 48px;
      padding: 0 18px 8px;
      vertical-align: bottom;
      letter-spacing: 0;
      text-overflow: ellipsis;
      color: rgba(0, 0, 0, 0.54);
      border-top: 0 !important;
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      background: white !important;
      font-weight: 700;
      line-height: 24px;
    }
  }
  td {
    position: relative;
    box-sizing: border-box;
    height: 48px;
    padding: 12px 18px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    &:first-of-type {
      padding-left: 24px;
    }
  }
  th {
    &:first-of-type {
      padding-left: 24px;
    }
  }
}

.dataTables_filter {
	margin-bottom:10px;
	input {
		padding:10px 15px;
		color: #8b8d94;
		border-width: 1px;
		border-color: rgba(51, 51, 51, 0.12);
		background-color: #fdfdfd;
		border-radius: 1px;
		
	}
}

.padding {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.row-blue .row {
  background: $blue;
}