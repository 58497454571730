.page-content {
    padding:15px;
    ul {
        list-style: disc outside none;
        margin: 0 0 10px 20px;
        li {
            padding-bottom: 5px;
        }
    }
    ol {
        margin: 0 0 10px 20px;
    }
    a {
        color: $red;
        text-decoration: underline;
        &.btn {
            color: white;
            text-decoration: none;
        }
    }
     p {

        margin-bottom: 10px;
    
    }
    h2 {
        color: $blue;
        font-size:32px;
        line-height: 1.2;
    }
    h3 {
        color: $red;
        font-size:24px;
        line-height: 34px;
        font-weight: bold;
    }
    h4 {
        color: $blue;
        font-size:20px;
        line-height: 30px;
    }
    h5 {
        font-size:16px;
        line-height: 26px;
        margin-bottom: 10px;
    }
    .row {
        padding-bottom:10px;
    }
}
