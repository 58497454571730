@charset "UTF-8";

@font-face {
  font-family: "icomoon";
  src: url("icomoon.eot");
  src: url("icomoon.eot#iefix") format("embedded-opentype"),
    url("icomoon.ttf") format("truetype"),
    url("icomoon.woff") format("woff"),
    url("icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-deadline::before {
  content: '\e900';
}

.icon-megaphone::before {
  content: '\e901';
}

.icon-folder::before {
  content: '\e902';
}

.icon-family::before {
  content: '\e903';
}

.icon-syringe::before {
  content: '\e904';
}

.icon-microscope::before {
  content: '\e905';
}

.icon-stethoscope::before {
  content: '\e906';
}

.icon-pills::before {
  content: '\e907';
}
