.article-list {
    display:flex;
    text-align: left;
    margin-bottom: 20px;
    line-height: 1.2;
    align-items:flex-start;
    &:hover {
        .article-list__featured-image {
            &:after {
                opacity:0.6;
            }
        }
    }
    &__featured-image {
        min-width:60px;
        position:relative;
        margin: 0 20px 0 0;
        &:after {
            content: '';
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:4px;
            opacity:0;
            background: transparentize($red, 0.7);
            transition: all 350ms ease-in-out;
        }
        img {
            display:inline-block;
            font-size: 0;
            line-height: 0;
            min-width:60px;
            max-width: 60px;
            height: auto;
        }
    }
    h4 {
        font-size:15px;
        margin-bottom:5px;
    }
    &__date {
        color: lighten($black, 20%);
    }
}