.sidebar {
    > div {
        padding-top:20px;
    }
    padding: 20px 0;
    &__left {
        margin-right: -1rem;
        @include for-phone-only {
            margin-right: 0;
        }
    }
    &__right {
        margin-left: -1rem;
        @include for-phone-only {
            margin-left: 0;
        }
    }
    h2 {
        text-transform: uppercase;
        text-align:center;
        color: currentColor;
        font-size: 20px;
        line-height: 34px;
        padding: 0 10px;
        margin-bottom:20px;
    }
    ul {
        margin: 0 0 10px 20px;
    }
    padding-bottom: 0;
}


.learndash-cpt {
    .sidebar {
        display: none !important;
    }
}