footer {
    background: $grey;
    padding:2em 0;
    @include for-phone-only {
        text-align:center;
    }
    .widget {
        @include for-phone-only {
            padding-bottom: 20px;
        }
        line-height:1.2;
        h3 {
            color: $blue;
            text-transform:uppercase;
            font-size:26px;
            font-weight: 900;
            margin-bottom:0.8em;
            font-family: "Source Sans Pro";
        }
        a {
            text-decoration:none;
            color: $maroon;
        }
        .tel {
            font-size:18px;
            font-weight:600;
            color:$maroon;
        }
        .mail {
            font-weight:600;
            font-size:18px;
            color:$black;
            transition: all 350ms ease-in-out;
            &:hover {
                color:$maroon;
            }
        }
        .address {
            strong {
                color: $blue;
            }
            margin-top:1em;
            font-size:1em;
        }
        
    }
    .button {
        text-align:center;
        margin-bottom:1em;
    }
}