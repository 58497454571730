.btn {
  padding: 12px 18px;
  border-radius: 100px;
  color: $white;
  text-transform: uppercase;
  line-height: 1em;
  text-decoration: none;
  box-sizing: border-box;
  transition: all 350ms ease-in-out;
  display: inline-block;
  animation-duration: 2s;
  font-size:14px;
  &-red {
    background: $red;
  }
  &-blue {
    background: $blue;
  }
  &-small {
    font-size: 10px;
    padding: 6px 12px;
  }
  &-medium {
    font-size: 12px;
    padding: 8px 14px;
  }
  &-large {
    font-size: 18px;
    padding: 16px 24px;
    i {
      vertical-align: -3px;
    }
  }
  &-block {
    display:block;
    text-align:center;
    margin-bottom: 10px;
  }
  &:hover {
    //opacity: 0.8;
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15);
    color:white;
  }
  i {
    padding-right: 10px;
    font-size: 1.2em;
    vertical-align: -5px;
    font-size:21px;
  }
}
