.column {
    padding:20px 15px;
    &__title {
        text-align:center;
    }
    &--blue {
        background: $blue;
        color: white;
        h2 {
            color: white;
        }
    }
}