.board-list {
    padding: 20px;
    h2 {
        text-align: center;
        color:currentColor;
        font-size:24px;
        line-height:34px;
    }
    ul {
        list-style:none;
        margin:0;
        display:flex;
        flex-wrap: wrap;
        margin-top:10px;
        li {
            padding: 10px;
            list-style: none;
            text-align: center;
            color: white;
            font-weight: bolder;
            width: 33.3%;
            min-width: 100px;
        }
    }
}

.underline-red {
    h2 {
        &:after {
            content:'';
            display:block;
            width: 20%;
            height: 1px;
            background: $red;
            margin:auto;
            margin-top:10px;
        }
    }
}

.underline-blue {
    h2 {
        &:after {
            content:'';
            display:block;
            width: 20%;
            height: 1px;
            background: $blue;
            margin:auto;
            margin-top:10px;
        }
    }
}