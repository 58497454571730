nav { 
    &#nav-main {
        display: flex;
        @include for-phone-only {
            display:none;
        }
    }
        ul {
            list-style: none;
            margin:0;
            padding:0;
            li {
                display:inline-block;
                text-align:left;
                position:relative;
                padding:0.4em;
                &.current-menu-item > a {
                    color:$red;
                }
                a {
                    text-decoration: none;
                    color: $black;
                    text-transform:uppercase;
                    font-size:14px;
                    font-weight: 600;
                    transition:all 350ms ease-in-out;
                    &:hover {
                        color:$red;
                        &.has-child {
                            &:after{
                                color: $red;
                            }
                    }
                }
                    &.has-child {
                        &:after{
                            content:'\f078';
                            font-family: 'Font Awesome 5 Pro';
                            display:inline-block;
                            color: $black;
                            width:0.8em;
                            height:0.8em;
                            margin-left:0.2em;
                            font-size: 10px;
                            transition:all 350ms ease-in-out;
                        }
                    }
                }
                &:hover {
                    .nav-drop {
                        opacity:1;
                        visibility: visible;
                    }
                }
                .nav-drop {
                    opacity:0;
                    visibility: hidden;
                    position:absolute;
                    z-index: 200;
                    top: 100%;
                    left:0;
                    min-width:280px;
                    text-align:left;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
                    padding:0.4em;
                    background:white;
                    box-sizing:border-box;
                    transition:all 350ms ease-in-out;
                    > li {
                        padding:0.4em;
                        background: white;
                        transition:all 350ms ease-in-out;
                        width:100%;
                        a {
                            font-weight:200;
                            font-size:13px;
                            text-transform: none;
                        }
                        &:hover {
                            background: lighten($red, $amount: 40)
                        }
                    }
                }
                
            }
        }
}

.hamburger-icon {
    display:none;
    position:absolute;
    top: 50%;
    right: 15%;
    transform: translateY(-50%);
    cursor: pointer;
    @include for-phone-only {
        display:block;
    }
    span {
        width: 35px;
        height: 3px;
        background-color: #444;
        margin: 6px 0;
        display:block
      }
}

.slideout-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 256px;
    min-height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 0;
    display: none;
  }
  
  .slideout-menu-left {
    left: 0;
  }
  
  .slideout-menu-right {
    right: 0;
  }
  
  .slideout-panel {
    position: relative;
    z-index: 1;
    will-change: transform;
    background-color: #FFF; /* A background-color is required */
    min-height: 100vh;
  }
  
  .slideout-open,
  .slideout-open body,
  .slideout-open .slideout-panel {
    overflow: hidden;
  }
  
  .slideout-open .slideout-menu {
    display: block;
  }

  nav.mobile_menu {
      padding: 10px;
      ul {
        margin-top: 20px;
          li {
              display:block !important;
              padding: 10px;
              border-bottom: 1px solid $grey;
              .nav-drop {
                  height: auto;
                  position:relative;
                  left:auto;
                  top:auto;
                  opacity: 1;
                  visibility: visible;
                  box-shadow: none;
                  padding:0;
                  margin-top:0;
                  min-width: auto;
                  li {
                      padding: 10px 0;
                      padding-left:10px;
                  }
              }
          }
      }
      .close {
          position: absolute;
          width: 30px;
          height: 30px;
          right: 10px;
          top:10px;
          span {
              width: 30px;
              height: 3px;
              background: red;
              display:block;
              position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
                &:nth-of-type(2) {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
          }
      }
      .menu_widgets {
          margin-top:10px;
          text-align: center;
          .btn {
              margin: 5px;
          }
      }
  }