.contact {
    &_form {
      background: $blue;
      padding: 20px 0;
      position:relative;
      h2 {
        color: white;
        text-align: center;
      }
      .or-sign {
        
        position: absolute;
        left: 50%;
        top: 10px;
        width: 40px;
        height: 40px;
        background: $maroon;
        border-radius: 100%;
        color: white;
        text-align: center;
        line-height: 40px;
        transform: translateX(-50%);
        font-weight: bold;
        font-size: 18px;
        @include for-phone-only {
          display:none;
        }
      }
    }
    &_main {
      padding: 40px 0;
      line-height: 1.4;
      font-size:18px;
      h2 {
        color: $maroon;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;
      }
      strong {
        color: $blue;
      }
      a {
        color: $maroon;
        text-decoration: underline;
      }
    }
    &_info {
      padding: 20px 0;
      font-size: 15px;
      line-height: 1.4;
      &.bg-grey {
        background-color: #f9f9f9;
      }
      h3 {
        color: $maroon;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;
      }
      h4 {
        color: $blue;
        font-size: 20px;
        margin-bottom:10px;
      }
      a {
        color: $maroon;
        text-decoration: underline;
      }
    }
  }
  
  .acf-map {
    width: 100%;
    height: 400px;
}

// Fixes potential theme css conflict.
.acf-map img {
   max-width: inherit !important;
}