.step_accordion_section {
    padding: 15px;
    background: $red;
    margin-bottom: 20px;

    h2 {
        color: white;
    }

    .steps {
        &__container {
            display: grid;
            grid-template-columns: 1fr 4fr;
        }

        &__title {
            position: relative;
            cursor: pointer;
            padding: 8px 10px;

            h4 {
                color: white;
                font-size: 18px;
                line-height: 1.4;
            }

            &.is_active {
                background: white;

                h4 {
                    color: $red;
                }
            }
        }

        &__content {
            padding: 15px;
            background: white;
            line-height: 1.4;

            h3 {
                color: $red;
                font-size: 22px;
                line-height: 1.4;
                margin-bottom: 0.5rem;
            }

            p {
                margin-bottom: 10px;
            }

            a {
                color: $maroon;
                text-decoration: underline;
            }

            ul {
                margin: 0 0 10px 20px;
                list-style: disc outside none;

            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}