
.accordion_section {
    .accordion {
        padding: 10px;
        border-radius: 3px;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
        margin-bottom: 20px;
        &__title {
            padding-left:45px;
            position: relative;
            cursor: pointer;
            h4 {
                color: $red;
                font-weight: bold;
                font-size:20px;
                line-height: 30px;
            }
            &:before {
                content: '';
                width:15px;
                height: 2px;
                left:10px;
                top:50%;
                position: absolute;
                display:block;
                background: $blue;
                transform: translateY(-50%);
            }
            &:after {
                content: '';
                width:2px;
                height: 15px;
                left:16px;
                top:50%;
                position: absolute;
                display:block;
                background: $blue;
                transform: translateY(-50%);
                transition: all 350ms ease-in-out;
            }
            &.is-active {
                &:after {
                    height:0px;
                }
            }
        }
        &__content {
            padding: 10px;
            padding-left: 45px;
            line-height: 1.4;
            p {
                margin-bottom: 10px;
            }
            a {
                color: $maroon;
                text-decoration: underline;
            }
            ul {
                margin: 0 0 10px 20px;
                list-style: disc outside none;

            }
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
