.clearfix {
  float: none;
  clear: both;
}

.home h2 {
  text-transform: uppercase;
}

h2 {
  color: $black;
  font-weight: 700;
  font-size: 24px;
  padding-bottom: 0.5em;
  line-height: 1.2;
}

.heading-white {
  h2 {
    color: white !important;
  }
}

.heading-blue {
  h2 {
    color: $blue !important;
  }
}

.title-xl {
  font-size: 30px;
}

.twitter iframe {
  max-height: 260px;
}

.page-container {
  font-size: 15px;
  line-height: 18px;
  text-align: left;
  .page-content,
  .normal-content, 
  .article-body,
  .post__content {
    font-size: 18px;
    p,
    li,
    h5,
    ul {
      font-size: 18px !important;
      line-height: 1.4;
    }
  }
}

.sidebar {
  .normal-content {
    font-size: 15px !important;
    p {
      font-size: 15px !important;
      line-height: 1.2;
    }
  }
}

em {
  font-style: italic;
}

.app-wrapper {
  li {
    list-style: none;
    position: relative;
    line-height: 1.2;
    padding-bottom: 5px;
    i {
      position: absolute;
      left: -20px;
      top: 3px;
    }
  }
  .text {
    margin-bottom: 10px;
  }
  .btn {
    text-align: center;
    border: 0 !important;
  }
}

.app-phone {
  padding: 0 25px;
  padding-left: 40px;
}

.no-bg-img {
  background-image: none;
}
.no-pad-tp {
  padding-top: 0;
}
.no-pad-bm {
  padding-bottom: 0;
}
.no-pad-lt {
  padding-left: 0;
}
.no-pad-rt {
  padding-right: 0;
}
.pad-tp {
  padding-top: 20px;
}
.pad-tp-lrg {
  padding-top: 60px;
}
.pad-bm {
  padding-bottom: 20px;
}
// .margin-minus {
//   margin-top: -20px;
// }
.uppercase {
  text-transform: uppercase;
  font-weight: bold;
}
.blue {
    color: $blue !important;
}
.ceo-left {
  margin-top: -15px;
  margin-bottom: -15px;
  background: $blue;
  padding-top: 20px;
  text-align: center;
  @include for-phone-only {
    margin:10px 0;
  }
  figure,
  img {
    display: inline-block;
    padding-bottom: 10px;
  }
  h3 {
    color: white;
    font-size: 18px;
    line-height: 1;
  }
  h4 {
    color: #333;
    font-size: 16px;
    font-weight: 600;
  }
  a {
    margin-bottom: 0px;
    text-decoration: none;
    color: white;
  }
}

.aligncenter {
  margin: auto;
}
.alignleft {
  float: left;
}
.alignright {
  float: right;
}

.placeholder-image {
  min-width: auto;
  padding: 20px;
  border: 1px solid;
  opacity: 0.5;
}

.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &:after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }
}

.dates {
    .col-sm-3 {
        text-align:center;
        padding:40px 0;
        h4 {
            font-size: 22px;
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 10px;
        }
    }
}


.article-body {
  padding:40px 0;
  line-height: 1.6em;
  ul {
    margin: 0 0 10px 20px;
    list-style:disc;
  }
  p {
    padding-bottom: 10px;
  }
  a {
    color: $red;
  }
}


.order_1 {
  order: 1;
  &.buttons__column {
    @include for-phone-only {
      order: 0;
    }
  }
}

