.downloads {
    color: white;
    background-color: $maroon;
    text-align:center;
    padding: 20px 0;
    color: white;
    &.no-bg {
        background-image: none;
    }
    h2 {
        color: white;
        text-transform: uppercase;
        font-weight:700;
        font-size:20px;
        margin-bottom:20px;
    }
    h3 {
        text-transform: uppercase;
        font-weight:700;
        font-size:20px;
        margin-bottom:20px;
    }
}

.download {
    max-width: 180px;
    min-width:180px;
    box-sizing: border-box;
    color: white;
    margin: 15px 0;
    h5 {
        color: white;
        font-size: 14px;
        line-height: 1.2;
        font-weight: 700;
    }
    p {
        color: white;
    }
    &__wrapper {
        height: 200px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        box-sizing: border-box;
        position: relative;
        color: white;
        text-align:center;
        transition: all 350ms ease-in-out;
        &:hover {
            //transform: rotateY(180deg);
            .download {
                &__content {
                    opacity:0;
                }
                &__icon {
                    opacity:1;
                }
            }
        }
    }
    &__overlay{
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background: rgba(0, 0, 0, 0.7);
    }
    &__content {
        position: absolute;
        top:50%;
        width:100%;
        transform: translateY(-50%);
        padding:10px;
        transition: all 350ms ease-in-out;
    }
    &__icon {
        position: absolute;
        top:0;
        height: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        width:100%;
        //transform: rotateY(180deg);
        opacity:0;
        background-color: $blue;
        transition: all 600ms ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h5 {
            text-transform: uppercase;
        }
        img {
            width: 50px;
            display:inline-block;
            margin-top:10px;
        }
    }
}