.two_column_section {
  margin-bottom: 20px;
  padding-block: 15px;

  iframe {
    aspect-ratio: 16/9;
  }

  img {
    width: 100%;
    height: auto;
  }

  p {

    margin-bottom: 10px;

  }

  h2 {
    display: block;
    font-size: 1.6em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    color: inherit;
  }

  h3 {
    display: block;
    font-size: 1.4em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h4 {
    display: block;
    font-size: 1.2em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  ul,
  ol {
    display: block;
    list-style: disc outside none;
    margin: 1em 0;
    padding: 0 0 0 40px;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    display: list-item;
    padding-bottom: 5px;
  }

  a {
    text-decoration: underline;
    color: inherit;
  }
}