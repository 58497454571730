input[type='text'],
input[type='search'],
input[type='tel'],
input[type='url'],
input[type='email'],
input[type='number'],
input[type='date'],
input[type='range'],
input[type='password'],
select,
.wpcf7-numbera {
    height: 38px;
    border: 0;
    background: rgba(255, 255, 255, 0.2);
    padding: 5px 10px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 0 !important;
    outline: none !important;
    color: $black;
    &::placeholder {
        opacity: 1;
        color: white;
    }
}
textarea {
    border: 0;
    background: rgba(255, 255, 255, 0.2);
    padding: 5px 10px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 0 !important;
    outline: none !important;
    color: $black;
    &::placeholder {
        opacity: 1;
        color: white;
    }
}

input[type='submit'] {
    width: 200px;
    border:0;
    color: white;
    display: block;
    margin: auto;
    margin-top:10px;
}
.dataTables_wrapper {
    input {
        color: #8b8d94;
        border: 1px solid rgba(51,51,51,0.12);
        background-color: #fdfdfd;
        border-radius: 1px;
    }
    select {
        color: #8b8d94;
        border: 1px solid rgba(51,51,51,0.12);
        background-color: #fdfdfd;
        border-radius: 1px;
    }
} 

